import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/home/logo_Sm.png";
import { BiMap, BiTimeFive } from "react-icons/bi";
import { GoMail } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../i18n/LanguageSwitcher";
import "./style.css";

export const NavBar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const navigate = useNavigate();


  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }
}, [isOpen]);


const onClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
  return (
    <>
      <div className="info">
        <div className="contact-data">
          <p className="time">
            <span><BiTimeFive /></span>{t('openingHours')}
          </p>
          <p className="mail">
            <span><GoMail /></span>info@smaccounting.am
          </p>
          <p className="phone-number">
          <span><FiPhoneCall /></span> +374  (11) 488 282
          </p>
        </div>
        <div className="address">
          <p className="map-text">
            <span><BiMap /></span>{t('address')}
          </p>
        </div>
      </div>
      <nav className={`navbar ${isFixed ? "fixed" : ""}`}>
        <div className="logo" onClick={onClick}>
          <img src={logo} alt="*" />
        </div>
        <div className={`nav-links ${isOpen ? "open" : ""}`}>
          <NavLink to="/" onClick={() => setIsOpen(false)}>{t('home')}</NavLink>
          <NavLink to="/about" onClick={() => setIsOpen(false)}>{t('about')}</NavLink>
          <NavLink to="/services" onClick={() => setIsOpen(false)}>{t('services')}</NavLink>
          <NavLink to="/contact" onClick={() => setIsOpen(false)}>{t('contact')}</NavLink>
          <LanguageSwitcher setIsOpen={setIsOpen} />
          </div>
        <div className={`new-toggle ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(!isOpen)}>
          <div className="bar"></div>
        </div>
      </nav>
    </>
  );
};
