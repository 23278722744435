import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import "./style.css";
import { useNavigate } from "react-router-dom";

export const Home2 = () => {
    const { t , i18n} = useTranslation();
    const navigate = useNavigate();

    const onClick = () => {
        navigate('/');
        window.scrollTo({
            left: 0,
            behavior: 'smooth'
        });
    };

    const getImagePath = (imageName) => {
        const lang = i18n.language;
        return require(`../../assets/images/${lang}/${imageName}`);
    };


    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.home_peges_img, .home_peges_content');
            elements.forEach(e => {
                const rect = e.getBoundingClientRect();
                if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                    if (e.classList.contains('home_peges_img')) {
                        e.classList.add('in_view_img');
                    } else if (e.classList.contains('home_peges_content')) {
                        e.classList.add('in_view_content')
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="home_peges">
            <img className="home_peges_img" src={getImagePath('home3.jpg')} alt="" />
            <div className="home_peges_content">
                <h2>{t('internshipAgreement')}</h2>
                {t('paragraphs-1', { returnObjects: true }).map((text, index) => (
                    <p key={index}>{text}</p>
                ))}
                <button onClick={onClick}>{t('return')}</button>
            </div>
        </div>
    );
};
