import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

export const About = () => {
    const { t } = useTranslation();

    return (
        <div className="about-us">
            <div className="about-img">
                <div className="about-text">
                    <h1>{t('about')}</h1>
                    <h6><span>{t('breadcrumb').split(' / ')[0]}</span> / {t('breadcrumb').split(' / ')[1]}</h6>
                </div>
            </div>
            <div className="about-content">
                <p>{t('paragraph1')}</p>
                <p>{t('paragraph2')}</p>
                <p>{t('paragraph3')}</p>
                <p>{t('paragraph4')}</p>
            </div>
        </div>
    );
};
