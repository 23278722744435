import React, { useEffect } from "react";
import "./style.css";
import SliderComponent from "../../components/slider/SliderComponent";

import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.home_1_img, .home_content1, .content_img_title, .content_number, .home2_item, .home_content_title');
            elements.forEach(e => {
                const rect = e.getBoundingClientRect();
                if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                    if (e.classList.contains('home_1_img')) {
                        e.classList.add('in_view1');
                    } else if (e.classList.contains('home_content1')) {
                        e.classList.add('in_view2');
                    } else if (e.classList.contains('content_img_title')) {
                        e.classList.add('in_view3');
                    } else if (e.classList.contains('content_number')) {
                        e.classList.add('in_view4');
                    } else if (e.classList.contains('home2_item')) {
                        e.classList.add('in_view5');
                    } else if (e.classList.contains('home_content_title')) {
                        e.classList.add('in_view6');
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const numbers = document.querySelectorAll('.number');
        const counters = Array(numbers.length);
        const intervals = Array(counters.length);
        counters.fill(0);

        numbers.forEach((number, index) => {
            intervals[index] = setInterval(() => {
                if (counters[index] === parseInt(number.dataset.num)) {
                    clearInterval(counters[index])
                } else {
                    counters[index] += 1;
                    number.innerHTML = counters[index];
                }
            }, 50)
        })
    }, []);


    const getImagePath = (imageName) => {
        const lang = i18n.language;
        return require(`../../assets/images/${lang}/${imageName}`);
    };

    const homeContent = [
        {
            img: getImagePath('home2.jpg'),
            title: t('businessRiskAnalysis'),
            description: t('serviceDescription1'),
            path: '/services/1'
        },
        {
            img: getImagePath('home3.jpg'),
            title: t('probationaryContract'),
            description: t('serviceDescription2'),
            path: '/services/2'
        },
        {
            img: getImagePath('home4.jpg'),
            title: t('civilLawContract'),
            description: t('serviceDescription3'),
            path: '/services/3'
        },
    ];

    const onServic = () => {
        navigate('/services');
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="home">
            <SliderComponent />
            <div className="home_1">
                <img className="home_1_img" src={getImagePath('home1.jpg')} alt="" />
                <div className="home_content1">
                    <h2><span>SM Accounting</span> {t('aboutCompany')}</h2>
                    <p>
                        {t('abouteCompanyText')}
                    </p>
                    <div className="home_service">
                        <div className="home_content1_2">
                            <h3>{t('ourServices')}</h3>
                            <p><span><FaChevronRight /></span>{t('accountingManagement')}</p>
                            <p><span><FaChevronRight /></span> {t('accountingBaseRecovery')}</p>
                            <p><span><FaChevronRight /></span>{t('taxConsulting')}</p>
                            <p><span><FaChevronRight /></span>{t('taxPlanning')}</p>
                            <p><span><FaChevronRight /></span>{t('taxInspectionSupport')}</p>
                        </div>
                        <div className="home_content1_2">
                            <h3>{t('ourMission')}</h3>
                            <p><span><FaChevronRight /></span>{t('financialReliability')}</p>
                            <p><span><FaChevronRight /></span> {t('accountingAccuracy')}</p>
                            <p><span><FaChevronRight /></span>{t('serviceQuality')}</p>
                            <p><span><FaChevronRight /></span>{t('economicSecurity')}</p>
                        </div>
                    </div>
                    <button onClick={onServic}>{t('learnMore')}</button>
                </div>
            </div>
            <div className="home_content_img">
                <div className="content_img_1">
                    <h4 className="content_img_title">{t('companyRating')}</h4>
                    <div className="content_number">
                        <div className="number" data-num="8"></div>
                        <p>{t('yearsExperience')}</p>
                    </div>
                    <div className="content_number">
                        <div className="number" data-num="230"></div>
                        <p>{t('happyClients')}</p>
                    </div>
                    <div className="content_number">
                        <div className="number" data-num="300"></div>
                        <p>{t('projectsCompleted')}</p>
                    </div>
                </div>
            </div>
            <div className="home_content_title">
                <h2><span>SM Accounting</span> {t('companyStaff')}</h2>
                <p>{t('companyStaffText')}</p>
            </div>
            <div className="home_content2">
                {homeContent.map((item, index) => (
                    <div key={index} className="home2_item">
                        <img src={item.img} alt={item.title}/>
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                        <button onClick={() => handleNavigation(item.path)}>{t('learnMore')}</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;
